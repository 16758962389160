import React from 'react';

import Shuffle from 'shufflejs'

import Loading from '../../common/Loading';
import Picture from '../Picture';
import ModalPicture from '../ModalPicture';
import qwest from 'qwest';

import {SiteInfo} from '../../../SiteInfo';

class Instagram extends React.Component {

	constructor(props) {

        super(props);

        this.state = {
					sectionData:props.sectionData,
					page:props.page,
					loading:true,
					items: [],
					pageNumber:0,
					hasMoreItems: true,
					pageReady:false,
					itemsPerLine:3,
					selectedPicture:null,
					height: window.innerHeight,
					imageReadyCount:0
        };

				this.handleScroll = this.handleScroll.bind(this);

				this.element = React.createRef();
        this.sizer = React.createRef();
				this.loader =  <Loading key={-1} type="minimal"/>

				this.loadingItems=false;
    }

		handleItemClick(picture)
		{
			 this.setState({selectedPicture: picture});
		}

		loadItems()
		{
				var self = this;
				var pageSize=10;
				var url=null;
				let SiteInfo = this.context;
				var pageId=this.state.page.id;

				//api/instagram/items?pageId=24&pageNumber=1&pageSize=10
				url = SiteInfo.baseUrl + '/api/instagram/pictures';

				if(this.state.nextHref) {
						url = this.state.nextHref;
				}

				self.setState({
						loading:true
				});

				qwest.get(url, {
							pageId:pageId,
							pageSize:pageSize,
							pageNumber:self.state.pageNumber,

						}, {
								cache: true
						})
						.then(function(xhr, resp)
						{
								if(resp)
								{
										var items = self.state.items;
										resp.map((track) => {
											return items.push(track);
										});

									if(resp.length===pageSize)
									{
												self.setState({
														items: items,
														pageNumber:self.state.pageNumber+1,
														pageReady:true,
														loading:false
												});
									}
									else
									{
												self.setState({
														items: items,
														hasMoreItems: false,
														pageReady:true,
														loading:false
												});
									}
								}
								else
								{
									self.setState({
											hasMoreItems: false,
											pageReady:true,
											loading:false
									});
								}

								self.loadingItems=false;
						});
		}

		componentDidMount()
		{
			window.addEventListener("scroll", this.handleScroll);

			this.loadItems();
		}

		componentDidUpdate()
    {
			this.createShuffle();

      if(this.state.pageReady)
      {
          if(this.shuffle)
          {
						this.shuffle.resetItems();
					}
      }
  	}

		createShuffle()
		{
			if(!this.shuffle)
			{

					// The elements are in the DOM, initialize a shuffle instance.
					this.shuffle = new Shuffle(this.element.current, {
							itemSelector: '.picture-item',
							sizer: this.sizer.current,
					});
			}
		}

		filterChanged(group)
    {
			if(this.shuffle && group)
					this.shuffle.filter(group);
    }

		handleScroll()
		{
			if(this.loadingItems || !this.state.hasMoreItems)
			{

				return;
			}

			const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
			const body = document.body;
			const html = document.documentElement;
			const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
			const windowBottom = windowHeight + window.pageYOffset;
			if (windowBottom >= docHeight-300)
			{
				this.loadingItems=true;
				this.loadItems();
			}
	 }

	 pictureLoaded(picture)
	 {
			this.setState({imageReadyCount:this.state.imageReadyCount+1})

			if(this.state.imageReadyCount===this.state.items.length)
			{
				if(this.shuffle)
						this.shuffle.destroy();

				this.shuffle = new Shuffle(this.element.current, {
						itemSelector: '.picture-item',
						sizer: this.sizer.current,
				});
			}
	 }

		render() {
					var pictures = [];
					this.state.items.map((picture, i) => {

							return pictures.push(
								<Picture
									key={i}
									picture={picture}
									//templateType={templateType}
									onDetailClick={(picture) => this.handleItemClick(picture)}
									onPictureLoaded={(picture)=>this.pictureLoaded(picture)}/>
							);
					});

					if(pictures.length>0)
					{
						this.filterChanged(null)
					}
        return (
					<>

					<section id="portfolio" >
						<div className="container">
						
						<ul ref={this.element} className="row portfolio list-unstyled lightbox my-shuffle">
								{this.state.pageReady?pictures:undefined}
							 <div ref={this.sizer} className="col-xs-6 col-md-4 photo-grid__sizer"></div>
						</ul>
						{this.state.loading?this.loader:undefined}
						</div>
						<ModalPicture
								picture={this.state.selectedPicture}/>
						</section>
					</>
        );
    }
}

export default Instagram
Instagram.contextType = SiteInfo;
