import React from 'react';

import { Link } from "react-router-dom";
/*
var styleArticleTitleDiv = {
    width:"40%",
		paddingLeft:"25px",
    height:"100%"
};

var styleArticleTitleInsideDiv = {
		paddingTop:"auto",
    paddingBottom:"auto"
};

var styleImgTitleDiv = {
    maxHeight:"300px"
};
*/
class ArticlePreview extends React.Component {

	constructor(props) {
        super(props);

        this.state = {
					article:props.article,
					diplayType:props.displayType,
          type:props.type,
          articleCategories:props.articleCategories
        };
    }


	render() {

		if(this.state.article)
    	var link="/articles/"+this.state.article.id

    var groups="[]";

    if(this.state.articleCategories!=null)
		{
			groups=JSON.stringify(this.state.articleCategories)
    }

		var displayTypeApplied=null;
		var item=undefined;
		var liclassName="col-xs-6 col-md-4 project m-project article-item";

		if(this.state.diplayType==="reduced")
		{
			if(this.state.type==="seeMore")
				displayTypeApplied="reduced_seeMore"
			else
				displayTypeApplied="reduced"

				liclassName="col-xs-6 col-md-3 project m-project article-item";
		}
		else
		{
			if(this.state.type==="seeMore")
				displayTypeApplied="normal_seeMore"
			else
				displayTypeApplied="normal"
		}

		switch(displayTypeApplied)
		{
			case "normal":
				item=<><div className="blog block post-content-area">
						<Link to={link}>
							<img src={this.state.article.coverImageUrl} alt=""/>
						</Link>
						<div className="post-info-box">
								<h3>{this.state.article.title}</h3>
								<p className="post-meta">by <Link to={this.state.article.author.userUrl}>{this.state.article.author.displayName}</Link></p>
								<p>{this.state.article.summary}</p>
								<p className="blog-post-footer">
										<Link to={link} className="btn btn-primary-filled"><span>Read More</span><i className="fa fa-long-arrow-right"></i></Link>
								</p>
						</div>
				</div><div className="space-50">&nbsp;</div></>
      /*<div className="media blog post-content-area blog-white blog-custom-border">
					<div className="media-left" style={styleArticleTitleDiv}>
            <div style={styleArticleTitleInsideDiv}>
						        <Link to={link}><h5 className="media-heading">{this.state.article.title}</h5></Link>
                    <p>by <Link to={this.state.article.author.userUrl}>{this.state.article.author.displayName}</Link></p>
            </div>
					</div>
					<div className="media-body" style={styleImgTitleDiv} >
						<Link to={link}>
							<img src={this.state.article.coverImageUrlSmall} alt="" />
			 			</Link>
					</div>
				</div>*/
			break;
			case "normal_seeMore":
					item=<div className="img-bg-color primary">
						<Link to="/articles">
							<div className="project-details">
									<h5 className="project-title">See all the articles</h5>
							</div>
						</Link>
					</div>
				break;
			case "reduced":
				item=<li key={this.state.key} className={liclassName} data-groups={groups}>
					<Link to={link}>
						<div id="blog">
							<div className="blog block blog-posts-widget blog-posts-small">
								<div  className="blog-post-small">
										<img src={this.state.article.coverImageUrlSmall} alt=""/>
										{this.state.article.title}
								</div>
							</div>
						</div>
					</Link>
				</li>
				break;
				case "reduced_seeMore":
					item=<li key={this.state.key} className={liclassName} data-groups={groups}>
						<Link to="/articles">
						<div id="blog">
							<div className="blog block blog-posts-widget blog-posts-small">
								<div  className="blog-post-small">
										See all the articles
								</div>
							</div>
						</div>
					</Link>
				</li>
					break;
			default:
			break;
		}

		return (
			<>
				{item}
		</>
	)
	}
}

export default ArticlePreview
