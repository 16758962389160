import React from 'react';

import qwest from 'qwest';

import Header from '../common/Header';
import Footer from '../common/Footer';
import Loading from '../common/Loading';
import Shuffle from 'shufflejs'

import FilterButton from '../objects/shuffle/FilterButton';
import ArtistItem from '../objects/shuffle/ArtistItem';
import {SiteInfo} from '../../SiteInfo';

class Artists extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            artists: [],
            artistsReady:false,
            filterButtons:null,
            categoriesReady:false,
            filtersCreated:false
        };

        this.element = React.createRef();
        this.sizer = React.createRef();

        this.filterChanged = this.filterChanged.bind(this);
    }

    loadCategories()
    {
        var self = this;
        let SiteInfo = this.context;
        var url = SiteInfo.baseUrl + '/api/artistCategory';

        qwest.get(url, {}, {cache: true})
            .then(function(xhr, resp) {
                if(resp) {
                    var categories = self.state.categories;
                    resp.map((categoriesList) => {
						return categories.push(categoriesList);
                    });

                    self.setState({
                        categories: categories,
                        categoriesReady:true
                    });
				}
            });
    }

    loadArtists()
    {
        var self = this;
        let SiteInfo = this.context;
        var url = SiteInfo.baseUrl + '/api/artist';

        qwest.get(url, {}, {cache: true})
            .then(function(xhr, resp) {
                if(resp) {
                    var artists = self.state.artists;
                    resp.map((artistsList) => {
						                return artists.push(artistsList);
                    });

                    self.setState({
                        artists: artists,
                        artistsReady:true
					});

				}

            });
    }

    componentDidMount()
    {
        this.loadCategories();
        this.loadArtists();
    }

    componentDidUpdate()
    {
        // Notify shuffle to dump the elements it's currently holding and consider
        // all elements matching the `itemSelector` as new.

        if(this.state.categoriesReady && this.state.artistsReady && !this.state.filtersCreated)
        {
            this.createFilters()
            this.setState({filtersCreated: true});
        }

        if(this.state.categoriesReady && this.state.artistsReady)
        {
            if(this.shuffle)
                this.shuffle.resetItems();
        }
    }

    componentWillUnmount()
    {
        // Dispose of shuffle when it will be removed from the DOM.
        if(this.shuffle)
            this.shuffle.destroy();
        this.shuffle = null;
    }

    createFilters()
    {
        this.setState({filterButtons:<FilterButton
                    key="1"
                    categories={this.state.categories}
                    onClick={(group) => this.filterChanged(group)}/>})
    }

    filterChanged(group)
    {
        if(!this.shuffle)
        {
            // The elements are in the DOM, initialize a shuffle instance.
            this.shuffle = new Shuffle(this.element.current, {
                itemSelector: '.artist-item',
                sizer: this.sizer.current,
            });
        }

        if(this.shuffle)
            this.shuffle.filter(group);
    }

    render() {
        var users=[];
        this.state.artists.map((artist, i) => {

            var link="/"+artist.sectionPath+"/";

            return   users.push(<ArtistItem
                        key={i}
                        displayName={artist.displayName}
                        profileImage={artist.profileImage}
                        mainImage={artist.mainImage}
                        url={artist.url}
                        link={link}
                        artistCategories={artist.artistCategories}/>)
        });

        return (
          <div>
			       <Header type={'home'}/>
               <section id="portfolio" className="info-box">
                  <div className="container">
                      <ul className="portfolio-filter list-inline text-center">
                        {this.state.filterButtons==null && users.length===0?
                          <Loading/>: this.state.filterButtons}
                      </ul>
                      <ul ref={this.element} className="row portfolio list-unstyled lightbox my-shuffle">
                          {users}
                          <div ref={this.sizer} className="col-xs-6 col-md-4 photo-grid__sizer"></div>
                      </ul>
                  </div>
                </section>
              <Footer/>
            </div>
        )
    }
}

export default Artists
Artists.contextType = SiteInfo;
