import React from 'react';
import {SiteInfo} from '../../../SiteInfo';

import Loading from '../../common/Loading';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

var reCaptchaKey='6LesxpMUAAAAAKGXYZyfi2YPobsq8GRWT1xgmLJw'

var csrfTokenValue='thisismybadcsrftoken';

class BuyForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      email: '',
      message: '',
      picture: props.picture,
      owner: props.owner,
      captchaResponse:'',
      csrfToken: csrfTokenValue,
      onSuccess:props.onSuccess,
      onError:props.onError,
      loading:false,
      errorText:props.errorText,
      tokenId:0
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeFullName = this.handleChangeFullName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);

  }

  verifyCallback = (recaptchaToken) => {
    this.setState({captchaResponse: recaptchaToken});
  }

  componentDidMount() {
    //loadReCaptcha(reCaptchaKey);
  }

  handleChangeFullName(event)
  {
    this.setState({fullName: event.target.value});
  }

  handleChangeEmail(event)
  {
    this.setState({email: event.target.value});
  }

  handleChangeMessage(event)
  {
    this.setState({message: event.target.value});
  }

  handleSubmit(event)
  {
      let SiteInfo = this.context;
      var url = SiteInfo.baseUrl + '/api/contact';
      var self=this;

      this.setState({
        loading: true
      })

      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': this.state.csrfToken
        },
        body: JSON.stringify({
          title: 'title',
          fullName: this.state.fullName,
          email: this.state.email,
          message: this.state.message,
          sectionId:this.state.sectionId,
          itemId:this.state.picture.id,
          captchaResponse: this.state.captchaResponse
        }),
        }).then((response) => {


          response.json().then(function(data)
          {
            if(data)
            {
              self.onSuccess()
            }
            else {
              this.onError()
            }
          });

        }).catch((error) => {
          this.onError()
        });

      event.preventDefault();
  }

  onSuccess()
  {
    this.state.onSuccess(true)

    this.setState({
      loading: false
    })
    this.resetRecaptcha()
  }

  onError()
  {
    this.state.onError()
    this.setState({
      loading: false
    })

    this.resetRecaptcha()
  }

  // create a reset function
  resetRecaptcha()
  {
    var tokenId=this.state.tokenId+1;
    this.setState({tokenId:tokenId})
  }


  static getDerivedStateFromProps(newProps, state)
  {
    var picture=state.picture;
    var owner=state.owner;
    var message=null;

    if(state.owner!==newProps.owner)
    {
      owner=newProps.owner;
    }

    if(state.picture!==newProps.picture)
    {
      picture=newProps.picture;

      if(owner && picture)
      {
        message="Hi "+owner.displayName+",\n\nI am interested in "+picture.title+".\n\nThank you\n\nBest regards";
      }
    }

    if(message==null)
    return {
      picture:picture,
      owner:owner,
      errorText:newProps.errorText
    };
    else {
      return {
        picture:picture,
        owner:owner,
        message:message,
        errorText:newProps.errorText
      };
    }
  }

  render() {
    var srcImg=undefined;
    var price=undefined;
    if(this.state.picture)
    {
      srcImg=this.state.picture.imageUrlNormal;
      price=this.state.picture.price;
    }

    const loader =  <Loading key={-1}/>

      return (
        <div className="modal-body">
          <form id="contactForm" onSubmit={this.handleSubmit} name="formContact">
        
          <div className="row">
            <div>{this.state.errorText?
              <div className="alert alert-danger alert-dismissible" role="alert">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span></button>
                    <strong>Error!</strong> {this.state.errorText}
                </div>:undefined}</div>
            <div className="col-md-4">
                <img src={srcImg} alt="" />
                <label className="col-form-label">Price: {price}$</label>
            </div>
            <div className="col-md-8">
              <div className="form-group">
                <input type="text"
                    className="form-control"
                    name="inputFullName"
                    value={this.state.fullName}
                    onChange={this.handleChangeFullName}
                    placeholder="FULL NAME" required />
                <input type="email"
                    className="form-control"
                    id="inputContactEMail"
                    name="inputContactEMail"
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                    placeholder="EMAIL" required />

                <label className="col-form-label">Message:</label>
                <textarea id="inputMessage"
                    className="form-control"
                    rows="10"
                    name="inputMessage"
                    value={this.state.message}
                    onChange={this.handleChangeMessage}
                    placeholder="MESSAGE" required></textarea>
              </div>
            </div>
            <div className="col-md-12">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</div>
          </div>
          <button type="button" className="btn btn-secondary" onClick={()=>this.state.onSuccess(false)}>Close</button>
          <span className="pull-right">
            {this.state.loading?
              loader:
              <button type="submit" id="buyPictureSubmit" className="btn btn-primary">Send message</button>}
          </span>
          </form>
          <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
              <GoogleReCaptcha onVerify={this.verifyCallback} />
          </GoogleReCaptchaProvider>
        </div>
      )
  }
}

export default BuyForm
BuyForm.contextType = SiteInfo;
