import React from 'react';

import { Link } from "react-router-dom";

class ArtistItem extends React.Component {

	constructor(props) {
		super(props);

		this.state = {

			displayName:props.displayName,
			profileImage:props.profileImage,
			mainImage:props.mainImage,
			link:props.link,
			artistCategories:props.artistCategories
		};
	}

	render() {
		var groups="[]";
		var userCategories=null;
		if(this.state.artistCategories!=null)
		{
			groups=JSON.stringify(this.state.artistCategories)

			this.state.artistCategories.map((category) => {
					if(userCategories==null)
						return userCategories=category;
					else
						return userCategories+=","+category;
				});
		}

		return (
			  <li key={this.state.key} className="col-xs-6 col-md-4 project artist-item" data-groups={groups}>
                <div className="img-bg-color primary">
                    <Link to={this.state.link}>
						<img src={this.state.mainImage?this.state.mainImage:'/theme_mp/images/unknownMain.png'} alt=""/>
                            <div className="project-details project-details-custom clearfix">
                            <div className="blog-custom-avatar">
                                <img src={this.state.profileImage?this.state.profileImage:'/theme_mp/images/unknownUser.png'} className="img-circle" alt=""/>
                            </div>
                            <div className="project-custom-details">
                                <h5 className="project-title">{this.state.displayName}</h5>
                                <p className="skill">{userCategories}</p>
                            </div>
                        </div>
                    </Link>
                </div>
			</li>
			)
	}
}

export default ArtistItem
