import React from 'react';

import Header from '../common/Header';
import Footer from '../common/Footer';
import PicturesPage from '../objects/PicturesPage';

class Shop extends React.Component {

	constructor(props) {
        super(props);

        this.state = {

        };
    }


	render() {

		return (
			<div>
        <Header type={'home'}/>
        <PicturesPage  type={"forSale"}/>
        <Footer/>
			</div>
		)
	}
}

export default Shop
