import React from 'react';

import Loading from '../common/Loading';
import qwest from 'qwest';
import Picture from './Picture';
import Album from './Album';
import ModalPicture from './ModalPicture';
import ModalBuy from './ModalBuy';
import ModalSimple from './ModalSimple';

import Shuffle from 'shufflejs'
import FilterButton from '../objects/shuffle/FilterButton';

import {SiteInfo} from '../../SiteInfo';
import { Link } from "react-router-dom";

class PicturesPage extends React.Component {

constructor(props) {

        super(props);

        this.state = {
            sectionData:props.sectionData,
            page:props.page,
						subPath: props.subPath,
						//subPathList: null,
            pageReady:false,
						pageRendered:false,

						filterButtons:null,
						pageError:null,
						selectedPicture:null,
						selectedPictureBuy:null,
						modalBuyVisible:false,

						type: props.type,

						//for albums
						album:null,

						//for pictures list
						pictures:null,

						//simple modal
						simpleModalText:null,
						simpleModalVisible:false
        };

				this.element = React.createRef();
        this.sizer = React.createRef();

				this.filterChanged = this.filterChanged.bind(this);
    }

    componentDidMount()
		{
			if(this.state.type==="forSale")
				this.loadItemsForSale()
			else if(this.state.sectionData)
				this.loadItems()
		}

		componentDidUpdate()
    {
			var self=this;

        if(this.state.album || this.state.pictures)
        {
            if(this.shuffle)
                this.shuffle.resetItems();
        }

				if(!this.state.pageRendered && this.state.pageReady && (this.state.album || this.state.pictures))
				{
					//init the shulffle after 500 ms to manage the mansory
					setTimeout(function(){
 						self.setState({pageRendered:true})
    			}, 500);
				}
    }

		componentWillUnmount()
		{
				// Dispose of shuffle when it will be removed from the DOM.
				if(this.shuffle)
						this.shuffle.destroy();
				this.shuffle = null;
		}

		loadItemsForSale()
		{
        var self = this;
        var url=null;
        let SiteInfo = this.context;
        url = SiteInfo.baseUrl + '/api/pictures';

        if(this.state.nextHref) {
            url = this.state.nextHref;
        }

        qwest.get(url, {
            forSale:true
            }, {
                cache: true
            })
            .then(function(xhr, resp) {
                if(resp)
								{
									if(resp.length===0)
									{
										self.setState({
												pageError:404,	//TOTO manage error
												pageReady:true,
										});
									}
									else
									{
										self.setState({
												pictures: resp,
                        pageReady:true,
                    });
									}
								}
								else {
									self.setState({
											pageError:404,
											pageReady:true,
									});
								}
            });
    }

    loadItems()
		{
        var self = this;
        var url=null;
				var selectedAlbum=this.state.subPath;
        let SiteInfo = this.context;

        url = SiteInfo.baseUrl + '/api/album';

        if(this.state.nextHref) {
            url = this.state.nextHref;
        }

        qwest.get(url, {
            section:this.state.sectionData?this.state.sectionData.path:undefined,
            page:this.state.page?this.state.page.path:undefined,
						path:selectedAlbum
            }, {
                cache: true
            })
            .then(function(xhr, resp) {
                if(resp)
								{
									if(resp.length===0)
									{
										self.setState({
												pageError:404,	//TOTO manage error
												pageReady:true,
										});
									}
									else
									{
										self.createFilters(resp[0].filters);

										self.setState({
												album: resp[0],
                        pageReady:true,
                    });

									}

								}
								else {

									self.setState({
											pageError:404,
											pageReady:true,
									});
								}
            });
    }

		createFilters(filters)
		{
			if(filters.length>0)
				this.setState({filterButtons:<FilterButton
										key="1"
										categories={filters}
										onClick={(group) => this.filterChanged(group)}/>})
		}

		filterChanged(group)
    {
        if(!this.shuffle)
        {
            // The elements are in the DOM, initialize a shuffle instance.
            this.shuffle = new Shuffle(this.element.current, {
                itemSelector: '.picture-item',
                sizer: this.sizer.current,
            });
        }

        if(this.shuffle && group)
            this.shuffle.filter(group);
    }

		handleItemClick(picture)
		{
			 this.setState({selectedPicture: picture});
		}

		handleItemBuyClick(picture)
		{
			this.setState({
				selectedPictureBuy: picture,
				modalBuyVisible:true});
		}

		onCloseModalBuy(success)
		{
			this.setState({
				modalBuyVisible:false});

				if(success)
				{
					this.setState({
						simpleModalText:"The message has been sent to "+this.state.sectionData.owner.displayName,
						simpleModalVisible:true
					})
				}
		}

		onCloseModalSimple()
		{
			this.setState({
				simpleModalVisible:false});

		}

		render()
		{
        const loader =  <Loading key={-1}/>
        var templateType="PAGE_CONTENT_TEMPLATE_GRID_TEXT_UNDER";
        var sectionClassName=undefined;
        if(this.state.page)
        {
          templateType=this.state.page.contentTemplate;
        }

				if(this.state.pageRendered && !this.state.shuffle)
				{
					this.filterChanged(null)
				}

				var albums = [];
				if(this.state.album)
				{
	        this.state.album.albums.map((album, i) => {
	            return albums.push(
	                <Album
										key={i}
	                    album={album}/>
	            );
	        });

				}

				var pictures = [];

				//type album
				if(this.state.album)
				{
	        this.state.album.pictures.map((picture, i) => {
	            return pictures.push(
	                <Picture
										key={i}
	                  picture={picture}
                    templateType={templateType}
										onDetailClick={(picture) => this.handleItemClick(picture)}
										onBuyClick={(picture) => this.handleItemBuyClick(picture)}/>
	            );
	        });
				}

				//type only pictures (no album)
				if(this.state.pictures)
				{
					this.state.pictures.map((picture, i) => {
	            return pictures.push(
	                <Picture
										key={i}
	                  picture={picture}
                    templateType={templateType}
										onDetailClick={(picture) => this.handleItemClick(picture)}
										onBuyClick={(picture) => this.handleItemBuyClick(picture)}/>
	            );
	        });
				}

				var albumsPathNav=[];

				if(this.state.album && this.state.album.parentAlbum)
				{
					var album=this.state.album;
					//BrowserRouter
					while(album)
					{

						//if root
						if(!album.parentAlbum)
							albumsPathNav.unshift(<span key={album.url}><Link to={album.url} >{album.title}</Link></span>)
						else
								albumsPathNav.unshift(<span key={album.url}>/<Link to={album.url} >{album.title}</Link></span>)

						album=album.parentAlbum;
					}
				}

        if(templateType==="PAGE_CONTENT_TEMPLATE_GRID_TEXT_UNDER")
          sectionClassName="info-box w-shadow";

				return (
						<section id="portfolio"  className={sectionClassName} >
						{this.state.pageReady?
							(this.state.pageError?'error page':
						    <div className="container">
									{this.state.album?
										<ul className="portfolio-filter list-inline"><h3>{this.state.album.title}</h3></ul>
										:
										<ul className="portfolio-filter list-inline"><h3>Shop</h3></ul>
									}
									{albumsPathNav}
									<ul className="row portfolio list-unstyled" >
										{albums}
									</ul>
						      <ul className="portfolio-filter list-inline text-center">
										{this.state.filterButtons==null?'': this.state.filterButtons}
						      </ul>
						      <ul ref={this.element} className="row portfolio list-unstyled lightbox my-shuffle">
						          {pictures}
						         <div ref={this.sizer} className="col-xs-6 col-md-4 photo-grid__sizer"></div>
						      </ul>
						    </div>):<div className="container"><ul className="portfolio-filter list-inline"></ul>{loader}</div>}
								<ModalPicture
									picture={this.state.selectedPicture}/>

								<ModalBuy	picture={this.state.selectedPictureBuy}
									owner={this.state.sectionData?this.state.sectionData.owner:undefined}
									onClose={(success)=>this.onCloseModalBuy(success)}
									visible={this.state.modalBuyVisible}/>
								<ModalSimple
										text={this.state.simpleModalText}
										visible={this.state.simpleModalVisible}
										onClose={()=>this.onCloseModalSimple()}
										/>
						</section>
        );
    }
}

export default PicturesPage
PicturesPage.contextType = SiteInfo;
