import React from 'react';

import Carousel from './Carousel';
import FlowPicture from './FlowPicture';


var stylePadding = {
	paddingBottom: "15px"
};

class FlowItem extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			key:null,
			item:props.item,
			onCarouselClick:props.onCarouselClick.bind(this),
			itemsPerLine:props.itemsPerLine,
			//for artist flow
            sectionId: props.sectionId
		};
	}

	render() {
		var link500px=null;
		var linkInstagram=null;
		var linkFacebook=null;
		var linkTwitter=null;

		if(this.state.item.owner.fiveHundredPxId)
		{
			link500px="https://500px.com/"+this.state.item.owner.fiveHundredPxId;
		}

		if(this.state.item.owner.instagramId)
		{
			linkInstagram="https://www.instagram.com/"+this.state.item.owner.instagramId;
		}

		if(this.state.item.owner.facebookId)
		{
			linkFacebook="https://www.facebook.com/"+this.state.item.owner.facebookId;
		}

		if(this.state.item.owner.twitterId)
		{
			linkTwitter="https://twitter.com/"+this.state.item.owner.twitterId;
		}

		var mainDivClassName=undefined
		switch(this.state.itemsPerLine)
		{
			case 1:
				mainDivClassName=undefined;
			break;
			case 3:
				mainDivClassName="col-sm-4 col-md-4";
			break;
			default:
				mainDivClassName=undefined;
			break;
		}

		return (
			<div className={mainDivClassName} style={stylePadding}>
				<div  key={this.state.key} className="blog post-content-area blog-white blog-custom-border">
					{this.state.sectionId?'':<div className="blog-custom-top">
						<div className="blog-custom-avatar">
							<a href={this.state.item.owner.userUrl}><img src={this.state.item.owner.profileImage} className="img-circle" alt=""></img></a>
						</div>
						<div className="blog-custom-author">
							<a href={this.state.item.owner.userUrl}><strong>{this.state.item.owner.displayName}</strong></a>
						</div>
						<span className="blog-custom-pull-right social">
						{
							link500px ?<a href={link500px} target="_blank" rel="noopener noreferrer"><i className="fa fa-500px"></i></a>:''
						}
						{
							linkInstagram ?<a href={linkInstagram} target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>:''
						}
						{
							linkFacebook ?<a href={linkFacebook} target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>:''
						}
						{
							linkTwitter ?<a href={linkTwitter} target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a>:''
						}
						</span>
					</div>}
					{this.state.sectionId ?
					<FlowPicture
							active={false}
							title={this.state.item.pictures[0].title}
							onClick={(picture) => this.state.onCarouselClick(picture)}
							picture={this.state.item.pictures[0]}
							/>
					:(
					this.state.item.flowContainers?
					<Carousel items={this.state.item.flowContainers}  onClick={(picture) => this.state.onCarouselClick(picture)}/>
					:undefined)}
				</div>
		</div>
			)
	}
}

export default FlowItem
