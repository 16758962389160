import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

var styleBelowNav = {
    top:"50px"
};

class Loading extends React.Component {

    constructor(props) {
          super(props);

          this.state = {
            type: props.type
          };
    }
    render() {

      var renderedLoad;

      switch(this.state.type)
      {
        case 'minimal':
        renderedLoad=<div  className="text-center">
          <FontAwesomeIcon icon={['fas', 'spinner']} spin size="3x" />
          <div className="space-50">&nbsp;</div>
        </div>
        break;
        case 'picture':
        renderedLoad=<li key={this.state.key} className="col-xs-6 col-md-4 project m-project picture-item" >
        <div className="spinner spinner-round"></div>
        </li>
        break;
        case 'fullScreen':
        renderedLoad= <div id="preloader" style={styleBelowNav}>
                        <div class="spinner spinner-round"></div>
                    </div>
        break;
        default:
        case 'normal':
        renderedLoad=<div className="spinner spinner-round"></div>
        break;
      }

        return (
          <>{renderedLoad}
           {/*this.state.minimalType?
        		<div  className="text-center">
        			<FontAwesomeIcon icon={['fas', 'spinner']} spin size="3x" />
        			<div className="space-50">&nbsp;</div>
        		</div>
            :
            <div className="spinner spinner-round"></div>*/}
          </>
        )
    }
}

export default Loading
