import React from 'react';
import { Helmet } from 'react-helmet'

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import qwest from 'qwest';
import Home from './components/views/Home';
import Artists from './components/views/Artists';
import Contact from './components/views/Contact';
import Artist from './components/views/Artist';
import Articles from './components/views/Articles';
import Article from './components/views/Article';
import Shop from './components/views/Shop';
import {SiteInfo} from './SiteInfo';
import Loading from './components/common/Loading';


class App extends React.Component {
  constructor(props) {
        super(props);

        this.state = {
          siteConfiguration:null,
          baseUrl:'https://api.ohartists.art'
          //baseUrl:'https://dev.munoz-torres.ch'
          //baseUrl:'http://localhost:8081'
        };
  }
  componentDidMount()
  {
      this.loadSiteConfiguration();
  }

  loadSiteConfiguration()
  {
      var self = this;
      var url = this.state.baseUrl + '/api/siteConfiguration';

      qwest.get(url, {}, {cache: true})
          .then(function(xhr, resp)
          {
              if(resp)
              {
                  self.setState({
                      siteConfiguration: resp
                   });
               }
          });
  }

render() {
  return (
    <>
    {this.state.siteConfiguration!=null?(
      <><Helmet>
        <title>{this.state.siteConfiguration.htmlTitle}</title>
        /*<meta property="og:title" content={this.state.siteConfiguration.ogTitle}/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content={this.state.siteConfiguration.ogUrl}/>
        <meta property="og:image" content={this.state.siteConfiguration.ogImage}/>
        <meta property="og:description" content={this.state.siteConfiguration.ogDescription}/>
        <meta property="og:locale" content="en_US"/>
        <meta property="fb:app_id" content={this.state.siteConfiguration.fbAppId}/>
        <meta property="author" content={this.state.siteConfiguration.htmlAuthor}/>
        <meta property="keywords" content={this.state.siteConfiguration.htmlKeywords}/>
        <meta property="description" content={this.state.siteConfiguration.htmlDescription}/>*/
      </Helmet>
    <SiteInfo.Provider value={{baseUrl: this.state.baseUrl,siteConfiguration:this.state.siteConfiguration}}>
      <Router>
          <div className="page-body-content">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/artists" component={Artists} />
              <Route exact path="/articles" component={Articles} />
              <Route path="/articles/:articleId?" component={Article} />
              <Route exact path="/shop" component={Shop} />
              <Route exact path="/contact" component={Contact} />
              <Route path="/:sectionPath/:pagePath?/:subPath*" component={Artist} />
            </Switch>
          </div>
        </Router>
      </SiteInfo.Provider></>):<Loading key={-1} />}</>
  );
  }
}

export default App;
App.contextType = SiteInfo;
