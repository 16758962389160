import React from 'react';

import { Link } from "react-router-dom";
import { Route } from "react-router-dom";

import {SiteInfo} from '../../SiteInfo';
var imgUrl="https://data.ohartists.com/home/banner2.jpg";

var sectionStyle = {
  backgroundImage: 'url(' + imgUrl + ')',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: '#ccc'
};

class Header extends React.Component {

	constructor(props) {
    	super(props);
    	this.state = {
			type:props.type,
			userId:props.userId,
			pages: props.pages
		};

	  }

	renderPath(artist,path,name) {

		var pathTotal="/"+ (artist?artist+'/':'') + path;
		var key="artist"+path+name;

    if(path==="")//for root page, use exact for the active rendering
    {
      return (
        <Route exact path={pathTotal} key={key}>
          {({ match }) => <li  className={match ? 'active' : undefined}><Link to={pathTotal}><span>{name}</span></Link></li>}
        </Route>
      );
    }
    else
    {
      return (
        <Route path={pathTotal} key={key}>
          {({ match }) => <li  className={match ? 'active' : undefined}><Link to={pathTotal}><span>{name}</span></Link></li>}
        </Route>
      );
    }

	}

	renderDefaultNav()
	{
		return (
			 <ul className="nav navbar-nav">
				{this.renderPath(null,'','home')}
				{this.renderPath(null,'artists','artists')}
        {this.renderPath(null,'articles','articles')}
        {this.renderPath(null,'shop','shop')}
				{this.renderPath(null,'contact','contact')}
			</ul>
		);
	}

	renderUserNav(artist)
	{
		var pagesRender=[];
		if(this.state.pages)
		{
			this.state.pages.map((page) => {
					return pagesRender.push(this.renderPath(artist,page.linkPath,page.title));
				});
		}

		return (
			 <ul className="nav navbar-nav">
			 	{pagesRender}
			</ul>
		);
	}

    render() {
		var belowHeader;
    let SiteInfo = this.context;

		if (this.state.type==='home_image')
		{
			belowHeader =  <div style={sectionStyle}>
				<div className="banner-content text-center">
					<div className="banner-info">
            <div dangerouslySetInnerHTML={{__html: SiteInfo.siteConfiguration.homeBannerInfoText}} />
            <br/>
            <br/>
              <Link to="/shop">
                <button type="button"  className="btn btn-primary-filled">Shop now <i className="fa fa-angle-double-right"></i>
                </button>
              </Link>
          </div>
				</div>
			</div>;
		} else {
			belowHeader = '';
		}

        return (
		<header className="site-header home-sticky-nav-trigger">
        <nav className="navbar navbar-default navbar-fixed-top">
            <div className="main-nav navbar ">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target=".navbar-collapse">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="/"><img src="https://data.ohartists.com/logos/OhArtists_s_200h.png" alt="logo" ></img>
                        </a>
                    </div>
                    <div className="navbar-collapse collapse">
						{this.state.type==='user'? this.renderUserNav(this.state.userId):this.renderDefaultNav()}
                    </div>
                </div>
            </div>
        </nav>
		{belowHeader}

      </header>
        )
    }
}

export default Header
Header.contextType = SiteInfo;
