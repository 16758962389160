import React from 'react';

import qwest from 'qwest';

import Loading from '../common/Loading';
import Header from '../common/Header';
import Footer from '../common/Footer';

import {SiteInfo} from '../../SiteInfo';

 class Article extends React.Component {

   constructor(props) {
         super(props);

         this.state = {
           articleId:this.props.match.params.articleId,
           article:null
         };

   }

   componentDidMount()
   {
       this.loadArticle();
   }

   loadArticle()
   {
       var self = this;
       let SiteInfo = this.context;
       var url = SiteInfo.baseUrl + '/api/article';

       qwest.get(url, {
         id:this.state.articleId
       }, {cache: true})
           .then(function(xhr, resp)
           {
               if(resp)
               {
                   var articles = [];
                   resp.map((articlesList) => {
                           return articles.push(articlesList);
                   });

                   self.setState({
                       article: articles[0],
                    });
                }
           });
   }

   render() {
        return (
          <>
      			<Header type={'home'}/>
      		  <section id="page-content" className="info-box w-shadow">
              {this.state.article?
              <div id="blog" className="container">
                <div className="blog post-content-area">
                    <h3>{this.state.article.title}</h3>
                    <div dangerouslySetInnerHTML={{__html:this.state.article.text}} />
                </div>
              </div>:<Loading />}
            </section>
            <Footer/>
      </>
        )
    }
}

export default Article
Article.contextType = SiteInfo;
