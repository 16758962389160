import React from 'react';

import { Link } from "react-router-dom";

import {SiteInfo} from '../../SiteInfo';

class Album extends React.Component {

	constructor(props) {

        super(props);

        this.state = {
            album:props.album
        };
    }

		render() {
        return (
          <li className="col-xs-6 project">
              <div className="img-bg-color primary">

                  <Link to={this.state.album.url}>
                    <img src={this.state.album.imageUrlMedium} alt="" />

                    <div className="project-details">
                      <h5 className="project-title">{this.state.album.title}</h5>
                    </div>
                  </Link>
              </div>
          </li>

        );
    }
}

export default Album
Album.contextType = SiteInfo;
