import React from 'react';


import {SiteInfo} from '../../SiteInfo';

class TextPage extends React.Component {

	constructor(props) {
        
        super(props);

        this.state = {
            page:props.page,
            brut:props.page
        };

    }

	render() {
        return (
            <div key={this.state.page.id}>
                {this.state.page.pageType==="PAGE_TYPE_TEXT"?
                <section id="portfolio" className="info-box w-shadow">
                    <div className="container">
                    <ul className="portfolio-filter list-inline"><h3>{this.state.page.title}</h3></ul>
                        <div dangerouslySetInnerHTML={{__html: this.state.page.pageText}} />
                    </div>
                </section>:
                <div dangerouslySetInnerHTML={{__html: this.state.page.pageText}} />}
           </div>
        );
    }
}

export default TextPage
TextPage.contextType = SiteInfo;
 