import React from 'react';

import Header from '../common/Header';
import Flow from '../objects/Flow';
import Footer from '../common/Footer';

import ArticlesHomeView from '../objects/Articles/ArticlesHomeView'

 class Home extends React.Component {

    render() {

        return (
		<>
			<Header type={'home_image'}/>
      <section id="portfolio" className="info-box">
        <div id="page-content" className="container">
            <ArticlesHomeView/>
            <div className="row">
              <div className="col-md-12"><h3>Artists recent work</h3></div>
            </div>
            <section id="blog">
    			       <Flow itemsPerLine={3}/>
            </section>
        </div>
      </section>
      <Footer/>
		</>
        )
    }
}

export default Home
