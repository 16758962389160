import React from 'react';

import BuyForm from './forms/BuyForm';

var styleHidden = {
    display: "none"
};

var styleVisible = {
    display: "block",
    paddingleft: "0px"
};

class ModalBuy extends React.Component {

    constructor(props) {
  		super(props);
  		this.state = {
              id:props.id,
              picture:props.picture,
              owner:props.owner,
              onClose:props.onClose,
              visible:props.visible,
              errorText:null
          };
    }

    static getDerivedStateFromProps(newProps, state)
    {
      var picture=state.picture;
      var owner=state.owner;
      var setVisible=state.visible;

      if(state.picture!==newProps.picture)
      {
        picture=newProps.picture;
      }

      if(state.owner!==newProps.owner)
      {
        owner=newProps.owner;
      }

      if(state.visible!==newProps.visible)
      {
        setVisible=newProps.visible;
      }

      return {
        picture:picture,
        owner:owner,
        visible:setVisible
      };
    }

    onBuySuccess(success)
    {
      // message onSuccess
      //hide modal modalPictureBuy
      this.state.onClose(success)
      this.setState({errorText:null})
    }

    onBuyError()
    {
      this.setState({errorText:'Error sending the message!'})
    }

    render() {

      var currentStyle=undefined;
      var classesUsed=undefined;

      if(this.state.visible)
      {
        currentStyle=styleVisible;
        classesUsed="modal fade in"
      }
      else {
        currentStyle=styleHidden;
        classesUsed="modal fade"
      }

  		return (
        <>
        <div  className={classesUsed}
              id="modalPictureBuy"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="modalPictureBuy"
              aria-hidden="true"
              style={currentStyle}
              >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
                <BuyForm picture={this.state.picture} owner={this.state.owner}
                  onSuccess={(success)=>this.onBuySuccess(success)}
                  onError={()=>this.onBuyError()}
                  errorText={this.state.errorText}/>
            </div>
        </div>
      </div>
      {this.state.visible?<div className="modal-backdrop in"></div>:undefined}
      </>
			)
	}
}

export default ModalBuy
