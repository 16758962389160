import React from 'react';

var modalContentStyle = {
    background:'black'
};

var modalColorWhite = {
    color:"white"
};

var modalPadding = {
    padding:"10px"
};

class ModalPicture extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            id:props.id,
            picture:props.picture
            /*title:props.title,
            text:props.text,
            date:props.date,
            imgUrl:props.imgUrl*/
        };
    }

	render() {

		return (
<div className="modal fade" id="modalPicture" tabIndex="-1" role="dialog" aria-labelledby="modalPicture" aria-hidden="true">
    <div className="modal-content-full"  style={modalContentStyle}>
        <span className="pull-right">
            <div className="close-modal" data-dismiss="modal" aria-hidden="true" style={modalPadding}>
            <a href="/#"><i className="fa fa-times"></i></a>
            </div>
         </span>
        <div id="page-image-content" className="full-height page-image-content">
            <div className="row full-height full-height">
                <div className="align-middle full-height">
                    <div className="full-height blog block about-sidebar-widget" id="photoFullDisplayImage">
                       <img src={this.props.picture?this.props.picture.imageUrlLarge:undefined}  className="img-responsive center-block modal-image" alt="" id="modalImage"/>
                    </div>
                </div>
			</div>
			<div className="photoFullDisplay">
				<h3 style={modalColorWhite} id="modalTitle">{this.props.picture?this.props.picture.title:undefined}</h3>
				<div style={modalColorWhite}>
                    <div>{this.props.picture?this.props.picture.date:undefined}</div>
                    <div>{this.props.picture?this.props.picture.details:undefined}</div>
                    <div>{this.props.picture?this.props.picture.text:undefined}</div>
                </div>
			</div>
        </div>
    </div>
</div>
			)
	}
}

export default ModalPicture
