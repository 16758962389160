import React from 'react';



class ErorPage extends React.Component {
	
    render() {
	
        return ( 
		<div  className="text-center">
			Error!
		</div>
        )
    }
}

export default ErorPage

