import React from 'react';

import {SiteInfo} from '../../SiteInfo';

var styleWhite = {
    color:"white"
};

var styleBlack = {
    color:"black"
};

var styleProjectHoverTools = {
    left:"0px",
    right:"0px"
};


class Picture extends React.Component {

	constructor(props) {

        super(props);

        this.state = {
            picture:props.picture,
            templateType:props.templateType,
            onDetailClick:props.onDetailClick,
            onBuyClick:props.onBuyClick,
            onPictureLoaded:props.onPictureLoaded,
            isPictureReaday:false,
        };
    }

    onPictureLoaded()
    {
      if(this.state.onPictureLoaded)
      {
          this.state.onPictureLoaded(this.state.picture)
          this.setState({isPictureReaday:true});
      }
    }

		render() {
      var groups="[]";
      var pictureTags=null;

  		if(this.state.picture.tags!=null)
  		{
  			groups=JSON.stringify(this.state.picture.tags)

  			this.state.picture.tags.map((tag) => {
  					if(pictureTags==null)
  						return pictureTags=tag;
  					else
  						return pictureTags+=","+tag;
  				});
  		}

      return (
            <li key={this.state.key} className="col-xs-6 col-md-4 project m-project picture-item" data-groups={groups} >
              {this.state.templateType==='PAGE_CONTENT_TEMPLATE_GRID_TEXT_UNDER'?(<>
              <div className="img-bg-color primary"  >
                	<img src={this.state.picture.imageUrlNormal} alt="" onLoad={() => this.onPictureLoaded()}/>
                  <div className="project-hover-tools" style={styleProjectHoverTools}>
                      <a href="#modal"
                          className="view-btn"
                          data-toggle="modal"
                          data-target="#modalPicture"onClick={() => this.state.onDetailClick(this.state.picture)}>
                          <i className="lnr lnr-eye"></i>
                      </a>
                  </div>
              </div>
              <div className="small-post-text">
                <a href="#modal"
                    data-toggle="modal"
                    data-target="#modalPicture"onClick={() => this.state.onDetailClick(this.state.picture)}>
                  <h5>{this.state.picture.title}</h5>
                </a>
                  <p className="space-bottom">{this.state.picture.text}</p>
                  <p className="small-post-footer small-post-meta">{this.state.picture.date}<br></br>
                    {this.state.picture.details}</p>
                  <span className="pull-right small-post-meta">{this.state.picture.salesStatus==="FOR_SALE"?
                      <a href="#buy"
                              className="project-link"
                              onClick={() => this.state.onBuyClick(this.state.picture)}>
                            <i className="fa fa-shopping-bag"></i> {this.state.picture.price?this.state.picture.price+' '+this.state.picture.currency.symbol:'Ask price'}
                          </a>
                    :(this.state.picture.salesStatus==="SOLD"?
                        <div><span className="fa-stack fa-1x">
                            <i className="fa fa-shopping-bag fa-stack-1x"></i>
                            <i className="fa fa-ban fa-stack-2x" style={styleBlack}></i>
                          </span>Sold</div>:'')}</span>
              </div>
                </>
            ):
                <div className="img-bg-color primary"  >
               		<img src={this.state.picture.imageUrlNormal} alt="" onLoad={() => this.onPictureLoaded()}/>
                    <div className="project-hover-tools" style={styleProjectHoverTools}>
                        <a href="#modal"
                            className="view-btn"
                            data-toggle="modal"
                            data-target="#modalPicture"onClick={() => this.state.onDetailClick(this.state.picture)}>
                            <i className="lnr lnr-eye"></i>
                        </a>
                    </div>
                    <div className="project-details">
                      <h5 className="project-title">{this.state.picture.title}
											<span className="pull-right">{this.state.picture.salesStatus==="FOR_SALE"?
												<a href="#buy"
                            		className="project-link"
																style={styleWhite}
                                onClick={() => this.state.onBuyClick(this.state.picture)}>
                              <i className="fa fa-shopping-bag"></i>
															{this.state.picture.price?this.state.picture.price+' '+this.state.picture.currency.symbol:'Ask price'}
                            </a>
                      :(this.state.picture.salesStatus==="SOLD"?
													<div><span className="fa-stack fa-1x">
                              <i className="fa fa-shopping-bag fa-stack-1x"></i>
                              <i className="fa fa-ban fa-stack-2x" style={styleBlack}></i>
                            </span>Sold</div>:'')}</span>
                      </h5>
                    </div>
                </div>}
            </li>

        );
    }
}

export default Picture
Picture.contextType = SiteInfo;
