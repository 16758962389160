import React from 'react';

import {SiteInfo} from '../../SiteInfo';

class Footer extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            userData: props.user
        };
    }

    render() {
      let SiteInfo = this.context;
      var link500px=null;
      var linkInstagram=null;
      var linkTwitter=null;
      var linkFacebook=null;

      var fiveHundredPxId;
      var instagramId;
      var twitterId;
      var facebookId;

      if(this.state.userData)
      {
        var owner=this.state.userData;

        if(owner.fiveHundredPxId)
          fiveHundredPxId=owner.fiveHundredPxId;

        if(owner.instagramId)
          instagramId=owner.instagramId;

        if(owner.twitterId)
          twitterId=owner.twitterId;

        if(owner.facebookId)
          facebookId=owner.facebookId;
      }
      else if(SiteInfo.siteConfiguration)
      {
        if(SiteInfo.siteConfiguration.instagramId)
          instagramId=SiteInfo.siteConfiguration.instagramId;

        if(SiteInfo.siteConfiguration.twitterId)
          twitterId=SiteInfo.siteConfiguration.twitterId;

        if(SiteInfo.siteConfiguration.facebookId)
          facebookId=SiteInfo.siteConfiguration.facebookId;
      }

      if(fiveHundredPxId)
        link500px="https://500px.com/"+fiveHundredPxId;

      if(instagramId)
        linkInstagram="https://www.instagram.com/"+instagramId;

      if(twitterId)
        linkTwitter="https://twitter.com/"+twitterId;

      if(facebookId)
        linkFacebook="https://www.facebook.com/"+facebookId;

      var footerText=undefined;

      if(this.state.userData)
      {
        footerText="© OhArtists - "+this.state.userData.displayName;
      }
      else
      {
        footerText="© OhArtists"
      }

        return (
		<footer className=" navbar-fixed-bottom">
  		<div className="container">
  			<p className="footer-info">{footerText}
    			<span className="social pull-right">
            {link500px?<a href={link500px} target="_blank" rel="noopener noreferrer"><i className="fa fa-500px"></i></a>:''}
            {linkInstagram?<a href={linkInstagram} target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>:''}
            {linkTwitter?<a href={linkTwitter} target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a>:''}
            {linkFacebook?<a href={linkFacebook} target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>:''}
    			</span>
  			</p>
  		</div>
		</footer>
        )
    }
}

export default Footer
Footer.contextType = SiteInfo;
