import React from 'react';

class FilterButton extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			categories:props.categories,
			onClick:props.onClick,
			includeFilterAll:true,
			selected:null
		};

		this.selectFilter = this.selectFilter.bind(this);

		this.element = React.createRef();
	}

	componentDidMount()
    {
		if(this.state.includeFilterAll)
		{
			this.setState({
			selected:'all'
			})
		}
    }

	selectFilter(group)
	{
		var self=this;

		self.state.onClick(group);

		this.setState({
			selected:group
		})
	}

	buttonAddCategory(filterName)
	{
		return 	<li key={filterName}>
					<a
					href="#filter"
					data-group={filterName}
					className={this.state.selected===filterName?'active':''}
					onClick={()=>this.selectFilter(filterName)}>
						{filterName}
					</a>
				</li>
	}

	render() {

		var filters=[];

		if(this.state.includeFilterAll)
		{
			filters.push(this.buttonAddCategory('all'));
			this.selected='all';
		}

		if(this.state.categories)
		{
			this.state.categories.map((category) => {
                return filters.push(this.buttonAddCategory(category.name));
            });
		}

		return (
			<ul className="portfolio-filter list-inline text-center">
                {filters}
            </ul>
			)
	}
}

export default FilterButton
