

import React from 'react';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import {SiteInfo} from '../../../SiteInfo';

var contactStyle = {
   color: "#ae9467"
};

var hiddenStyle = {
  display:'none'
};

var reCaptchaKey='6LesxpMUAAAAAKGXYZyfi2YPobsq8GRWT1xgmLJw'

var csrfTokenValue='thisismybadcsrftoken';

 class ContactForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contactPageText: props.contactPageText,
      fullName: '',
      email: '',
      message: '',
      sectionId: props.sectionId,
      captchaResponse:'',
      csrfToken: csrfTokenValue,
      formSubmitted: false,
      formErrorSend:null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeFullName = this.handleChangeFullName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);

    //const cookies = new Cookies();
    //cookies.set('CSRF-TOKEN', csrfTokenValue, { path: '/' });
  }

  verifyCallback = (recaptchaToken) => {
    console.log("recaptcha: "+recaptchaToken)
    this.setState({captchaResponse: recaptchaToken});
  }

  componentDidMount() {
    //loadReCaptcha(reCaptchaKey);
  }

  handleChangeFullName(event)
  {
    this.setState({fullName: event.target.value});
  }

  handleChangeEmail(event)
  {
    this.setState({email: event.target.value});
  }

  handleChangeMessage(event)
  {
    this.setState({message: event.target.value});
  }

  handleSubmit(event)
  {
      let SiteInfo = this.context;
      var url = SiteInfo.baseUrl + '/api/contact';

      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': this.state.csrfToken
        },
        body: JSON.stringify({
          title: 'title',
          fullName: this.state.fullName,
          email: this.state.email,
          message: this.state.message,
          sectionId:this.state.sectionId,
          captchaResponse: this.state.captchaResponse
        }),
        }).then((response) => {

          if(response.status!==200)
          {
              this.setState({formErrorSend:"Error sending the message, error: "+response.status});
          }
          else {
            this.setState({formSubmitted:true})
          }


        }).catch((error) => {
          this.setState({formErrorSend:"Error sending the message"});
        });

      event.preventDefault();
  }

  render() {
      return (
            <section id="portfolio" className="w-shadow">
              <div className="container">
                <ul className="portfolio-filter list-inline"><h3>Contact</h3></ul>
                    <div className="col-sm-6 contact-form-area">
                        <div id="contact-form">
                        <div style={this.state.formSubmitted ? undefined : hiddenStyle}>
                          The message have been send with success.
                        </div>
                          <div style={this.state.formSubmitted ? hiddenStyle : undefined}>
                          <div className="alert alert-danger alert-dismissible"
                              role="alert"
                              style={this.state.formErrorSend ?  undefined:hiddenStyle}>
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                            <strong>{this.state.formErrorSend}</strong>
                          </div>

                          <form id="contactForm" onSubmit={this.handleSubmit} name="formContact">
                            <div className="g-recaptcha"></div>
                              <input type="hidden" className="form-control" name="sectionId" value={this.state.sectionId} />
                              <div className="form-group">
                                <input type="text"
                                    className="form-control"
                                    name="fullName"
                                    value={this.state.fullName}
                                    onChange={this.handleChangeFullName}
                                    placeholder="FULL NAME" required />
                                <div className="help-block with-errors"></div>
                              </div>
                              <div className="form-group">
                                  <input type="email"
                                      className="form-control"
                                      id="inputContactEMail"
                                      name="inputContactEMail"
                                      value={this.state.email}
                                      onChange={this.handleChangeEmail}
                                      placeholder="EMAIL" required />
                                  <div className="help-block with-errors"></div>
                              </div>
                              <div className="form-group">
                                  <textarea id="inputMessage"
                                      className="form-control"
                                      rows="5"
                                      name="inputMessage"
                                      value={this.state.inputMessage}
                                       onChange={this.handleChangeMessage}
                                      placeholder="MESSAGE" required></textarea>
                                  <div className="help-block with-errors"></div>
                              </div>
                              <button type="submit" id="contactSubmit" className="btn btn-md btn-primary-filled btn-form-submit">SUBMIT FORM</button>
                              This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                          </form>
                          </div>

                        </div>
                    </div>
                <div className="col-sm-6 contact-text">
                  <div  style={contactStyle}>
                    <div dangerouslySetInnerHTML={{__html:this.state.contactPageText}} />
                    </div>
                </div>
              </div>
              <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
                  <GoogleReCaptcha onVerify={this.verifyCallback} />
              </GoogleReCaptchaProvider>

            </section>
      )
  }
}

export default ContactForm
ContactForm.contextType = SiteInfo;
