import React from 'react';

import FlowItem from './FlowItem';
import ModalPicture from './ModalPicture';

import Loading from '../common/Loading';
import InfiniteScroll from 'react-infinite-scroller';
import qwest from 'qwest';

import {SiteInfo} from '../../SiteInfo';

class Flow extends React.Component {

	constructor(props) {

        super(props);

        this.state = {
            items: [],
            hasMoreItems: true,
            page:0,
            nextHref: null,
            modalPicture:null,
            pageReady:false,
            sectionReady:false,
            flowReady:false,
						itemsPerLine:props.itemsPerLine,
            //for artist flow
            sectionData:props.sectionData
        };
    }

    loadItems() {
        var self = this;
        var pageSize=3;
        var url=null;
				var groupByArtist=undefined;
				var lastItems=undefined;
        let SiteInfo = this.context;
				var sectionId=undefined;
        if(self.state.sectionData)//for section
        {
					url = SiteInfo.baseUrl + '/api/flow';
					sectionId=self.state.sectionData.id;
				}
        else
        {
					url = SiteInfo.baseUrl + '/api/flowgroup';
					groupByArtist=true;
					lastItems=5;
				}

        if(this.state.nextHref) {
            url = this.state.nextHref;
        }

        qwest.get(url, {
            	max:pageSize,
                   startAt:self.state.page,
                   section:sectionId,
									 groupByArtist:groupByArtist,
									 lastItems: lastItems
            }, {
                cache: true
            })
            .then(function(xhr, resp) {
                if(resp) {
                    var items = self.state.items;
                    resp.map((track) => {
						return items.push(track);
                    });

                	if(resp.length===pageSize) {
                        self.setState({
                            items: items,
							//nextHref: resp.next_href
                            page:self.state.page+1,
                            flowReady:true
                        });
                    } else {
                        self.setState({
														tracks: null,
                            hasMoreItems: false,
                            flowReady:true
                        });
                    }
				}
				else
				{
					self.setState({
                        hasMoreItems: false,
                        flowReady:true
					});
                }
            });
    }

	handleCarouselItemClick(picture)
	{
		 this.setState({modalPicture: picture});
	}

	render() {
        const loader =  <Loading key={-1} type="minimal"/>

        var sectionId=null;

        if(this.state.sectionData)
            sectionId=this.state.sectionData.id;

        var items = [];
        this.state.items.map((flow, i) => {

            return items.push(
                <FlowItem
                    item={flow}
                    key={i}
                    sectionId={sectionId}
										itemsPerLine={this.state.itemsPerLine}
                    onCarouselClick={(picture) => this.handleCarouselItemClick(picture)}/>
            );
        });

        return (
			<>
            <div className="row">
                {this.state.sectionData && this.state.sectionData.flowPageAuthorSectionIncluded?<div className="col-lg-1"></div>:this.state.sectionData?<div className="col-lg-2"></div>:undefined}
                <>
								{this.state.sectionData?<div className="col-lg-8">
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadItems.bind(this)}
                        hasMore={this.state.hasMoreItems}
                        loader={loader}>
                            {items}
                    </InfiniteScroll>
								</div>:<InfiniteScroll
										pageStart={0}
										loadMore={this.loadItems.bind(this)}
										hasMore={this.state.hasMoreItems}
										loader={loader}>
												{items}
								</InfiniteScroll>}

                </>
							{this.state.sectionData && this.state.sectionData.flowPageAuthorSectionIncluded?
								<div className="col-md-4 col-lg-3">
                            <div className="blog post-content-area blog-white blog-custom-border">
                                {this.state.sectionData.flowPageAuthorSectionPicture?<img alt="" src={this.state.sectionData.flowPageAuthorSectionPicture} />:''}
                                <div className="post-info-box blog-custom-no-border"><h4>AUTHOR</h4>
                                    <p>{this.state.sectionData.flowPageAuthorSectionText}</p>
                                </div>
                            </div>
                            <div className="space-50">&nbsp;</div>
                        <div className="blog block categories-sidebar-widget"></div>
                    </div>:this.state.sectionData?<div className="col-lg-2"></div>:undefined}
            </div>
        <ModalPicture picture={this.state.modalPicture}/>
			</>
        );
    }
}

export default Flow
Flow.contextType = SiteInfo;
