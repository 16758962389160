import React from 'react';

import qwest from 'qwest';

import Loading from '../../common/Loading';
import ArticlePreview from './ArticlePreview'
import {SiteInfo} from '../../../SiteInfo';

class ArticlesHomeView extends React.Component {
  constructor(props) {
        super(props);

        this.state = {
          articles:[],
          articlesReady:false
        };
  }

  componentDidMount()
  {
      this.loadArticles();
  }

  loadArticles()
  {
      var self = this;
      let SiteInfo = this.context;
      var url = SiteInfo.baseUrl + '/api/article';

      qwest.get(url, {
          max:3
      }, {cache: true})
          .then(function(xhr, resp)
          {
              if(resp)
              {
                  var articles = self.state.articles;
                  resp.map((articlesList) => {
                          return articles.push(articlesList);
                  });

                  self.setState({
                      articles: articles,
                      articlesReady:true
                   });
               }
          });
  }

  fillArticlesPreview()
  {
    var articles=[];

    articles.push();
    articles.push(<ArticlePreview
        displayType={"reduced"}
        type={"seeMore"}/>);
  }

	render() {
    var articles=[];
    this.state.articles.map((article, i) => {
        return   articles.push(<ArticlePreview
          key={i}
          article={article}
          displayType={"reduced"}/>
      )
    });

    if(articles.length===3)
      articles.push(<ArticlePreview
        key={-1}
        displayType={"reduced"}
        type={"seeMore"}/>);

    return (
    <ul className="row portfolio list-unstyled lightbox">
    {this.state.articlesReady?articles:<Loading key={-1} type="minimal"/>}
    </ul>
  )
	}
}

export default ArticlesHomeView
ArticlesHomeView.contextType = SiteInfo;
