import React from 'react';

import FlowPicture from './FlowPicture';




class Carousel extends React.Component {

	constructor(props) {
		super(props);

		var num=Math.floor((Math.random() * 100000) + 1);
		this.state = {
			key:null,
			items:props.items,
			carouselId:num,
			onClick:props.onClick
		};

		this.pictureOnClick = this.pictureOnClick.bind(this);
	}

	pictureOnClick(picture)
	{
		this.state.onClick(picture);
	}

	render() {

		var carouselIDName="blog-carousel"+this.state.carouselId;
		var carouselLink="#"+carouselIDName;

		const carouselItems = this.state.items.map((carouselItem, i) =>
					<FlowPicture
						key={i}
						active={i===0}
						title={carouselItem.title}
						onClick={() => this.pictureOnClick(carouselItem.pictures[0])}
						picture={carouselItem.pictures[0]}

						/>
				);/*

			var carouselItem=this.state.items[0];

			const carouselItems=[1];

			carouselItems[0]=<FlowPicture
				key={0}
				active={true}
				title={carouselItem.title}
				onClick={() => this.pictureOnClick(carouselItem.pictures[0])}
				picture={carouselItem.pictures[0]}
				/>*/

		return (
				<div id={carouselIDName} className="carousel slide" data-ride="carousel">
					<div className="carousel-inner" role="listbox">
					{carouselItems}
					</div>
					<a className="left carousel-control" href={carouselLink} role="button" data-slide="prev">
						<span className="lnr lnr-chevron-left" aria-hidden="true"></span>
					</a>
					<a className="right carousel-control" href={carouselLink} role="button" data-slide="next">
						<span className="lnr lnr-chevron-right" aria-hidden="true"></span>
					</a>
				</div>
			)
	}
}

export default Carousel
