import React from 'react';

var styleDivOut = {
    position: "relative",
    paddingTop: "66.6666%"
};

var styleImgFix = {
	position: "absolute",
	height: "100%",
	left: "0px",
	top: "0",
	right: "0px"
};

class FlowPicture extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			key:null,
			active:props.active,
			title:props.title,
			picture: props.picture,
			items:props.items,
			onClick:props.onClick
		};

	}

	render() {
		return (
				<div className={this.state.active ? 'item active' : 'item'} key={this.state.picture.id}>
					<div style={styleDivOut}>
						<a href="#modal"
							data-toggle="modal"
							data-target="#modalPicture"
							onClick={() => this.state.onClick(this.state.picture)}>
							<img 	className="blog-img img-responsive center-block"
										src={this.state.picture.imageUrlLarge}
										alt=""
										style={styleImgFix}></img>
						</a>
					</div>
					<div className="post-info-box blog-custom-no-border">
						<div className="blog-content">
							<a href="#link"><h5>{this.state.title}</h5></a>
						</div>
					</div>
				</div>
			)
	}
}

export default FlowPicture
