import React from 'react';
import qwest from 'qwest';
import InfiniteScroll from 'react-infinite-scroller';

import Header from '../common/Header';
import Footer from '../common/Footer';
import Loading from '../common/Loading';

import ArticlePreview from '../objects/Articles/ArticlePreview'
import {SiteInfo} from '../../SiteInfo';

class Articles extends React.Component {

  	constructor(props) {
          super(props);

          this.state = {
            //articleId: this.props.match.params.articleId,
            items:[],
            page:0,
            hasMoreItems:true,
            articlesReady:false,
            pageReady:false,
            pageRendered:false,
            filtersCreated:false,
            filterButtons:null,
            categoriesReady:true,

            //filters
            filterTag:null,
            filterAuthor:null,

            authors:[],
            authorsReady:false,
          };
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
    }

		componentWillUnmount()
		{

		}

    componentDidMount()
    {
        this.loadAuthors();
    }

    updateArticlesList(userId)
    {
      this.setState({
          items: [],
          page:0,
          flowReady:false,
          hasMoreItems:true,
          filterAuthor:userId
      });
    }

    loadAuthors()
    {
        var self = this;
        let SiteInfo = this.context;
        var url = SiteInfo.baseUrl + '/api/articleAuthors';

        qwest.get(url, {}, {cache: false})
            .then(function(xhr, resp)
            {
                if(resp)
                {
                    self.setState({
                        authors: resp,
                        authorsReady:true,
                        pageReady:true
                    });
				        }
        });
    }

    loadItems()
    {
        var self = this;
        var pageSize=3;
        let SiteInfo = this.context;

        var url = SiteInfo.baseUrl + '/api/article';
        qwest.get(url, {
                max:pageSize,
                startAt:self.state.page,
                user:self.state.filterAuthor
            }, {
                cache: true
            })
            .then(function(xhr, resp) {
                if(resp)
                {
                    var items = self.state.items;
                    resp.map((track) => {
                      return items.push(track);
                    });

                    if(resp.length===pageSize)
                    {
                        self.setState({
                            items: items,
                            page:self.state.page+1,
                            flowReady:true
                        });
                    }
                    else
                    {
                        self.setState({
                            tracks: null,
                            hasMoreItems: false,
                            flowReady:true
                        });
                    }
                }
                else
                {
                  self.setState({
                                hasMoreItems: false,
                                flowReady:true
                  });
                }
      });
    }

    setUserFilter(userId)
    {
      this.updateArticlesList(userId);
    }

    render()
    {
        const loader =  <Loading key={-1} type="minimal"/>

        var articles=[];

        this.state.items.map((article, i) => {

            return articles.push(<ArticlePreview
              key={i}
              article={article}/>
          )
        });

        /*var authors=[];

        if(this.state.authorsReady)
        {
          authors.push(<div key="allAuhtors" className="post-category">
            <a href="#all"
                onClick={()=>this.setUserFilter(null)}>
                {this.state.filterAuthor===null?'>>':undefined}All <span className="pull-right">(10)</span>
            </a>
          </div>)

          this.state.authors.map((author, i) => {
              return   authors.push(<div key={i} className="post-category">
                  <a href="#author" onClick={()=>this.setUserFilter(author.id)}>{this.state.filterAuthor===author.id?'>>':undefined}{author.displayName} <span className="pull-right">(10)</span></a>
              </div>)
          });
        }*/


      return (
  		<>
      <div>
         <Header type={'home'}/>
           <div id="page-content" className="container">
              <section id="blog">
                  <div className="row">
                      {/*<div className="col-sm-4 col-md-3 blog-sidebar">
                          <div className="blog block categories-sidebar-widget">
                              <h4>CATEGORIES</h4>
                              <div className="post-category">
                                  <a href="#All">>>All <span className="pull-right">(10)</span></a>
                              </div>
                              <div className="post-category">
                                  <a href="#cat">Fashion <span className="pull-right">(3)</span></a>
                              </div>
                              <div className="post-category">
                                  <a href="#cat">Exhibitions <span className="pull-right">(7)</span></a>
                              </div>
                          </div>
                          <div className="space-50">&nbsp;</div>
                          <div className="blog block categories-sidebar-widget">
                                <h4>AUTHORS</h4>
                                {this.state.authorsReady?authors:<Loading key={-1} type="minimal"/>}
                            </div>
                      </div>
                      <div className="col-sm-8 col-md-9">
                        {this.state.pageReady?
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadItems.bind(this)}
                            hasMore={this.state.hasMoreItems}
                            loader={loader}>
                                {articles}
                        </InfiniteScroll>:undefined}
                      </div>*/}
                      <div className="col-md-2"></div>
                      <div className="col-md-8">
                        {this.state.pageReady?
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadItems.bind(this)}
                            hasMore={this.state.hasMoreItems}
                            loader={loader}>
                                {articles}
                        </InfiniteScroll>:undefined}
                      </div>
                        <div className="col-md-2"></div>
                  </div>
              </section>

          </div>
          <Footer/>
        </div>
  		</>
        )
    }
}

export default Articles
Articles.contextType = SiteInfo;
