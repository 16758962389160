import React from 'react';

var styleHidden = {
    display: "none"
};

var styleVisible = {
    display: "block",
    paddingleft: "0px"
};

class ModalSimple extends React.Component {

    constructor(props) {
  		super(props);
  		this.state = {
              text:props.text,
              visible:props.visible,
              onClose:props.onClose
          };
    }

    static getDerivedStateFromProps(newProps, state)
    {
      return {
        text:newProps.text,
        visible:newProps.visible
      };
    }

    closeModal()
    {
      this.state.onClose();
    }

    render() {

      var currentStyle=undefined;
      var classesUsed=undefined;

      if(this.state.visible)
      {
        currentStyle=styleVisible;
        classesUsed="modal fade in"
      }
      else {
        currentStyle=styleHidden;
        classesUsed="modal fade"
      }

  		return (
        <>
        <div  className={classesUsed}
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
              style={currentStyle}
              >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row">
                {this.state.text}
                <span className="pull-right">
                  <button type="button" className="btn btn-primary" onClick={()=>this.closeModal()}>Close</button>
                </span>
                </div>
              </div>
            </div>
        </div>
      </div>
      {this.state.visible?<div className="modal-backdrop in"></div>:undefined}
      </>
			)
	}
}

export default ModalSimple
