import React from 'react';
import { Helmet } from 'react-helmet'

import qwest from 'qwest';

import Loading from '../common/Loading';
import Header from '../common/Header';
import Footer from '../common/Footer';
import ErrorPage from '../common/ErrorPage';
import Flow from '../objects/Flow';
import PicturesPage from '../objects/PicturesPage';
import ContactForm from '../objects/forms/ContactForm';

import TextPage from '../objects/TextPage';
import Instagram from '../objects/Instagram/Instagram.js';

import {SiteInfo} from '../../SiteInfo';

class User extends React.Component {

	constructor(props) {
        super(props);

        this.state = {
					section:null,
					sectionPath: this.props.match.params.sectionPath,
					pages: null,
					pagesMap: null,
					pagesReady:false,
					pageError:false,
					currentPage:null,
					currentSubPath:null,
					pagePath:null,
					pageTitle:null,
					//pageType:null


					//page data
					pageContent:null
        };
    }

	getPageHashKey(pagePath)
	{
		if(!pagePath)
			pagePath="";

		return "path"+pagePath;
	}

 	componentDidMount()
    {
		this.loadSection()
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
		if(this.state.pagesReady
			&& (prevState.pagePath!==this.props.match.params.pagePath
					|| prevState.currentSubPath!==this.props.match.params.subPath) )
		{
			//set the current page selected
			var currentPageSelected=this.state.pagesMap[this.getPageHashKey(this.props.match.params.pagePath)];

			if(!currentPageSelected)
				this.setState({
					pageError: true
				});

			this.setState({
				pagePath:this.props.match.params.pagePath,
				currentPage:currentPageSelected,
				currentSubPath: this.props.match.params.subPath
			});

			this.updatePageContent();

		}
	}

	loadSection()
	{
        var self = this;
        let SiteInfo = this.context;
        var url = SiteInfo.baseUrl + '/api/section/'+this.state.sectionPath;

        qwest.get(url, {}, {cache: true})
            .then(function(xhr, resp) {
                if(resp)
								{
										var pagesMap=[];

										resp.pages.map((page,i) => {
											return pagesMap[self.getPageHashKey(page.linkPath)]=page;
										},this);

										 self.setState({
											section:resp,
											pages: resp.pages,
											pagesMap:pagesMap,
											pagesReady:true,
											pageTitle:null,
											//pageType:resp.pages[0].pageType,
											currentPage:null
										});
								}
            }).catch(function(e, xhr, response) {

	       			self.setState({
							pageError: true
	                    });
     		});
	}

	updatePageContent()
	{
		var newPageContent=null;

		if(this.state.currentPage)
		{
			switch(this.state.currentPage.pageType)
			{
			  case "PAGE_TYPE_FLOW":
					newPageContent=<div id="page-content" className="container">
													<section id="blog">
														<Flow sectionData={this.state.section} itemsPerLine={1} />
													</section>
												</div>;
				break;
				case "PAGE_TYPE_PICTURES":
					newPageContent=<PicturesPage sectionData={this.state.section}
										page={this.state.currentPage}
										subPath={this.state.currentSubPath}
										key={this.state.currentPage+"/"+this.state.currentSubPath} />;
				break;
				case "PAGE_TYPE_TEXT":
				case "PAGE_TYPE_TEXT_BRUT":
					newPageContent=<TextPage page={this.state.currentPage} key={this.state.currentPage.id} />;
				break;

				case "PAGE_TYPE_CONTACT":
					newPageContent=<ContactForm sectionId={this.state.section.id} contactPageText={this.state.section.contactPageText}/>
				break;

				case "PAGE_TYPE_INSTAGRAM":
					newPageContent=<Instagram sectionData={this.state.section} page={this.state.currentPage}/>
				break;

				default:
				break;
			}
		}

		this.setState({
				pageContent:newPageContent
			});
	}

	render() {
		var footer=null;

		if(this.state.section)
			footer=<Footer key="filled" user={this.state.section.owner}/>
		else
			footer=<Footer key="empty"/>;

		return (
			<div>

				{this.state.pageError?<ErrorPage/>:
					(this.state.currentPage?
					<div>
						<Helmet>
		          <title>OhArtists - {this.state.section.owner.displayName}</title>
		        </Helmet>
						<Header type={'user'} userId={this.state.sectionPath} pages={this.state.pages}/>
						{this.state.pageContent}
					</div>:
					<Loading/>)
				}
      {footer}
			</div>
		)
	}
}

export default User
User.contextType = SiteInfo;
