

import React from 'react';
//import Cookies from 'universal-cookie';

import Header from '../common/Header';
import Footer from '../common/Footer';
import ContactForm from '../objects/forms/ContactForm';
import {SiteInfo} from '../../SiteInfo';

var csrfTokenValue='thisismybadcsrftoken';

 class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contactPageText: props.contactPageText,
      fullName: '',
      email: '',
      message: '',
      sectionId: '',
      captchaResponse:'',
      csrfToken: csrfTokenValue,
      formSubmitted: false,
      formErrorSend:null
    };

  }
  render() {
      return (
        <div>
          <Header type={'home'}/>
            <ContactForm contactPageText={this.state.contactPageText}/>
            <Footer/>
        </div>
      )
  }
}

export default Contact
Contact.contextType = SiteInfo;
